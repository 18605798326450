@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Corinthia:wght@400;700&family=Inter:wght@100;200;300;400;600;700;800;900&display=swap");

body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Montserrat", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

.input__field,
.text__area {
  border: 2px solid #e2e2e2;
  width: 100%;
  padding: 1.3rem 1rem;
  margin-top: 0.3rem;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  resize: none;
}

.input__field2 {
  border: 1px solid #e2e2e2;
  border-radius: 0.4rem;
  color: #fff;
  background-color: transparent;
  width: 100%;
  padding: 1rem 3rem 1rem 0.6rem;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  resize: none;
}

.input__field:focus,
.input__field2:focus,
.text__area:focus {
  /* border: 1px solid #c1c1c1; */
  outline: none;
}

label {
  color: rgb(72, 72, 72);
  font-weight: 500;
  font-size: 1rem;
}
label span {
  color: rgb(251, 9, 9);
}

/* .text__area {
} */

.hero__img {
  background-image: url("./assets/images/hero-img.png");
  width: 100%;
  /* background-size: contain;; */
  background-size: cover;
  background-repeat: no-repeat;
}
.hero__img::before {
  content: "";
  position: absolute;
  background-image: linear-gradient(
    to right bottom,
    rgba(0, 0, 0, 0.882),
    rgba(0, 0, 0, 0.097)
  );
  height: inherit;
  /* opacity: .4; */
  width: inherit;
}

.img__cover {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  top: 0;
  /* left: 0; */
  right: 0;
  background-color: rgba(0, 0, 0, 0.907);
  opacity: 0.7;
}

.hero__text__start {
  height: 15rem;
  width: 4px;
  background-color: white;
  border-radius: 4rem;
}

.about__img1 {
  background-image: url("./assets/images/img3.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 50rem;
  width: "100%";
}
.about__img1::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-image: linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0));
  /* background-image: linear-gradient(
    to right bottom,
    rgba(0, 0, 0, 0.882),
    rgba(0, 0, 0, 0.097)
  ); */
  height: inherit;
  opacity: 0.7;
  width: inherit;
}

@media only screen and (max-width: 1024px) {
  .about__img1 {
    height: 25rem;
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.animate__flicker {
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 1s infinite;
  -o-animation: flickerAnimation 1s infinite;
  animation: flickerAnimation 1s infinite;
}

.wrapper {
  padding-top: 10rem;
}

.slide {
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
  text-align: center;
  height: 226px;
  background-color: #eee;
  border: 1px solid #ff6868;
  border-radius: 16px;
}

.swiper-container {
  height: 268px;
  /* width: 100%; */
  display: flex;
  /* background-color: red; */
}

.swiper-pagination {
  /* background-color: rgb(0, 255, 26); */
  /* width: "40%"; */
  display: flex;
  bottom: 0;
  margin-top: 3rem;
  justify-content: center;
}
.swiper-pagination-bullet {
  /* margin: auto; */
  width: 17px;
  height: 17px;
  margin-right: 0.3rem;
  background: #d9d9d9;
  /* border: 1px solid #a4a4a4; */
  opacity: 1;
  border-radius: 50%;
}

.swiper-pagination-bullet-active {
  width: 17px;
  height: 17px;
  transition: width 0.5s;
  border-radius: 50%;
  background: #05255f;
  border: 1px solid transparent;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.button {
  font-size: 1.2rem;
  font-family: inherit;
  color: #fff;
  background-color: #68b8ff;
  padding: 1rem 2rem;
  border-radius: 10px;
  border: none;
}
